import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { API_URL } from '../../../links/API_LINKS';
import ApplicantHeader from './ApplicantHeader';
import NewProfile from './NewProfile';
import ApplicationStatus from './profile/ApplicationStatus';

const Index = () => {
  const [showProfile, setShowProfile] = useState(null);
  const fetchApplications = async () => {
    const res = await axios.get(API_URL + '/dashboard/employer/applications', {
      params: {
        limit: 0,
      },
    });
    return res;
  };

  const { status, data } = useQuery('fetchApplications', fetchApplications);

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <Box>
          {showProfile ? (
            <>
              {/* <Profile user={showProfile} setShowProfile={setShowProfile} /> */}
              <ApplicationStatus user={showProfile} />
              <NewProfile
                userId={showProfile.userId}
                setShowProfile={setShowProfile}
              />
            </>
          ) : (
            <ApplicantHeader
              data={data.data.data}
              setShowProfile={setShowProfile}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Index;
