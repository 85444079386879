import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const ScoreBar = () => {
  return (
    <Box>
      <Grid container mb={5}>
        <Grid item md={6}>
          <Typography variant='p'>Skill Competency</Typography>
          <Box
            variant='span'
            mt={1}
            sx={{
              backgroundColor: 'primary.main',
              height: 35,
              borderTopLeftRadius: 12,
              borderBottomLeftRadius: 12,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            50%
          </Box>
        </Grid>
        <Grid item md={4}>
          <Typography variant='p'>Cognitive Acumen</Typography>
          <Box
            variant='span'
            mt={1}
            sx={{
              backgroundColor: 'primary.light',
              height: 35,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            40%
          </Box>
        </Grid>
        <Grid item md={2}>
          <Typography variant='p'>Profile Strength</Typography>
          <Box
            variant='span'
            mt={1}
            sx={{
              backgroundColor: 'primary.lighter',
              height: 35,
              borderTopRightRadius: 12,
              borderBottomRightRadius: 12,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            10%
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScoreBar;
