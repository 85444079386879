import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { SelectDomain } from './SelectDomain';
import SelectSkill from './SelectSkill';
import CustomSnackbar from '../layout/CustomSnackbar';
import AddCandidates from './AddCandidates';

export default function VerticalLinearStepper({ setValue }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [domainId, setDomainId] = React.useState('61b86aec2130e1002351eb74');
  const [skills, setSkills] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [openState, setOpenState] = React.useState(false);
  const [errorMsg, setErrorMessage] = React.useState('');

  function canProceedToNext() {
    setErrorMessage('');
    //Second step, check for at least 3 skills
    if (activeStep !== 1 || skills.length >= 3) {
      return true;
    } else {
      setErrorMessage('Please select at least 3 skills to proceed');
      return false;
    }
  }

  const handleNext = () => {
    if (canProceedToNext()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //Close snackbar
      setOpenState(false);
    } else {
      //Open snackbar
      setOpenState(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = [
    {
      label: 'Select a domain',
      content: (
        <SelectDomain
          domainId={domainId}
          setDomainId={setDomainId}
          setSkills={setSkills}
          handleNext={handleNext}
        />
      ),
    },
    {
      label: 'Select skills for the domnin',
      content: (
        <SelectSkill
          skills={skills}
          setSkills={setSkills}
          domainId={domainId}
        />
      ),
    },
    {
      label: 'Add candidates',
      content: (
        <AddCandidates
          domainId={domainId}
          skills={skills}
          candidates={candidates}
          setCandidates={setCandidates}
          setValue={setValue}
        />
      ),
    },
  ];

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant='caption'>Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Box sx={{ my: 4 }}>{step.content}</Box>
              <Box sx={{ my: 2 }}>
                <div>
                  {activeStep < 2 && (
                    <Button
                      variant={canProceedToNext ? 'contained' : 'outlined'}
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? 'Review' : 'Continue'}
                    </Button>
                  )}
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
      <CustomSnackbar
        setOpenState={setOpenState}
        openState={openState}
        severity='info'
        msg={errorMsg}
      />
    </Box>
  );
}
