import React from 'react';

import { Grid } from '@mui/material';

import ClassIcon from '@mui/icons-material/Class';
import PeopleIcon from '@mui/icons-material/People';
// import WorkIcon from '@mui/icons-material/Work';
// import PublicIcon from '@mui/icons-material/Public';
// import AssignmentIcon from '@mui/icons-material/Assignment';

import DataCard from '../layouts/DataCard';

const infoLighter = '#C8FACD';
const infoDarker = '#04297A';
const warningLighter = '#FFF7CD';
const primaryLighter = '#D0F2FF';
const errorLighter = '#FFE7D9';
const newighter = '#DCC1E3';

const Overview = () => {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <DataCard
            total='555'
            title='Role Posted'
            icon={ClassIcon}
            bgColor={errorLighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DataCard
            total='555'
            title='Total Applicants'
            icon={PeopleIcon}
            bgColor={newighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DataCard
            total='55'
            title='Shortlisted Applicants'
            icon={ClassIcon}
            bgColor={infoLighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DataCard
            total='94%'
            title='Applicantcant Rate'
            icon={ClassIcon}
            bgColor={warningLighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DataCard
            total='55'
            title='Offers Made'
            icon={ClassIcon}
            bgColor={primaryLighter}
            textColor={infoDarker}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;
