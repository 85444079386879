import axios from 'axios';
import { API_URL_V1 } from '../links/API_LINKS';

const axiosClient = axios.create({ baseURL: API_URL_V1 });

// interceptors
axiosClient.interceptors.request.use((config) => {
  config.headers['Authorization'] = '<Token Here>';
  return config;
});

// axios api
const api = {
  get: async ({ url, params = {}, data = {}, headers = {} }) => {
    return axiosClient({
      method: 'get',
      url,
      params,
      data,
      headers,
    });
  },
  post: async ({ url, params = {}, data = {}, headers = {} }) => {
    return axiosClient({
      method: 'post',
      url,
      params,
      data,
      headers,
    });
  },
  delete: async ({ url, params = {}, data = {}, headers = {} }) => {
    return axiosClient({
      method: 'delete',
      url,
      params,
      data,
      headers,
    });
  },
  put: async ({ url, params = {}, data = {}, headers = {} }) => {
    return axiosClient({
      method: 'put',
      url,
      params,
      data,
      headers,
    });
  },
  patch: async ({ url, params = {}, data = {}, headers = {} }) => {
    return axiosClient({
      method: 'patch',
      url,
      params,
      data,
      headers,
    });
  },
};

export default api;
