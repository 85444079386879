import axios from 'axios';
import {
  FETCH_EMPLOYE_REQUEST,
  FETCH_EMPLOYE_SUCCESS,
  FETCH_EMPLOYE_FAILURE,
} from './employeTypes';

import { API_URL } from '../../links/API_LINKS';

export const fetchEmployeDetails = () => {
  return (dispatch) => {
    dispatch(fetchEmployeRequest());
    axios
      .get(API_URL + '/admin/employer')
      .then((response) => {
        const employe = response.data;
        dispatch(fetchEmployeSuccess(employe));
      })
      .catch((error) => {
        dispatch(fetchEmployeFailure(error.message));
      });
  };
};

export const fetchEmployeRequest = () => {
  return {
    type: FETCH_EMPLOYE_REQUEST,
  };
};

export const fetchEmployeSuccess = (employe) => {
  return {
    type: FETCH_EMPLOYE_SUCCESS,
    payload: employe,
  };
};

export const fetchEmployeFailure = (error) => {
  return {
    type: FETCH_EMPLOYE_FAILURE,
    payload: error,
  };
};
