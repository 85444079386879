import React from 'react';
import ScoreChart from './ScoreChart';
import ScoreInfo from './ScoreInfo';
import { Box } from '@mui/material';

const AllScore = ({
  data,
  selectedDomain,
  setSelectedDomain,
  belongScore,
  areas,
}) => {
  return (
    <Box mt={3}>
      <ScoreInfo
        belongScore={belongScore}
        setSelectedDomain={setSelectedDomain}
        selectedDomain={selectedDomain}
        areas={areas}
      />
      <ScoreChart data={data} />
    </Box>
  );
};

export default AllScore;
