import React from 'react';
import { useField } from 'formik';
import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from '@mui/material';

export const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <TextField
        label={label}
        {...field}
        {...props}
        error={Boolean(meta.touched && meta.error)}
        helperText={meta.touched && meta.error}
      />
    </>
  );
};

export const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
      <Select
        label={label}
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        {...field}
        {...props}
        error={Boolean(meta.touched && meta.error)}
      />
      {meta.touched && meta.error && (
        <Typography
          variant='caption'
          display='block'
          ml={2}
          sx={{ color: 'error.main' }}
        >
          {meta.touched && meta.error}
        </Typography>
      )}
    </FormControl>
  );
};
