import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import employeReducer from './employe/employeReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  employe: employeReducer,
});

export default rootReducer;
