import { Button, Box, Grid, Typography, Stack, Alert } from '@mui/material';
import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextInput } from '../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useQueryClient } from 'react-query';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { format } from 'date-fns';

const AddCandidates = ({ assessment, setAssessment }) => {
  console.log(assessment);
  const queryClient = useQueryClient();
  return (
    <div>
      <Box my={2}>
        <Button variant='outlined' onClick={() => setAssessment(null)}>
          Back
        </Button>
      </Box>
      <Box>
        <Typography variant='h5' textAlign='center' mb={4}>
          Candidates added for assessment
        </Typography>
        <Alert severity='info'>
          Candidates listed below have received an email instructing them to
          take the assessments and complete their Belong profile at{' '}
          <a
            href='https://assessment.belong.education'
            rel='noreferrer noopener'
            target='_blank'
          >
            https://assessment.belong.education
          </a>
        </Alert>
      </Box>

      <Box mt={4}>
        {assessment.candidates.map((user, index) => {
          return (
            <Grid container mt={2}>
              <Grid item xs={4}>
                <Typography key={index}>{user.email}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>
                  Assessment open til{' '}
                  {format(new Date(user.submissionAllowedTill), 'do LLLL yyyy')}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Box>

      <Box mt={6}>
        <Formik
          enableReinitialize={true}
          //  innerRef={formikRef}
          initialValues={{
            employerAssessmentId: assessment._id,
            candidates: [{ name: '', email: '' }],
          }}
          validationSchema={Yup.object({})}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            axios({
              method: 'POST',
              url: API_URL + '/dashboard/employer/assessment/add-candidates',
              data: values,
            })
              .then(function (response) {
                queryClient.invalidateQueries('fetchAssessment');
                setSubmitting(false);
                setAssessment(null);
              })
              .catch(function (response) {
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Grid item xs={12} sm={8}>
                <div>
                  <FieldArray name='candidates'>
                    {({ insert, remove, push }) => (
                      <>
                        {values.candidates.length > 0 &&
                          values.candidates.map((candidate, index) => (
                            <Grid container key={index} spacing={4} mb={2}>
                              <Grid item>
                                <TextInput
                                  size='small'
                                  type='text'
                                  label='Candidate Name'
                                  name={`candidates.${index}.name`}
                                />
                              </Grid>
                              <Grid item>
                                <TextInput
                                  size='small'
                                  type='email'
                                  label='Candidate Email'
                                  name={`candidates.${index}.email`}
                                />
                              </Grid>
                              <Grid item>
                                {values.candidates.length > 1 && (
                                  <IconButton aria-label='delete' size='small'>
                                    <DeleteIcon
                                      fontSize='small'
                                      onClick={() => remove(index)}
                                    />
                                  </IconButton>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        <Button
                          variant='outlined'
                          startIcon={<AddIcon />}
                          onClick={() => push({ name: '', email: '' })}
                        >
                          Add Candidate
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </div>
              </Grid>
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Add Users
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                <Typography></Typography>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddCandidates;
