import React from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';
import Applicants from '../components/_dashboard/applicants/index';
const DashboardApplicants = () => {
  return (
    <Page title='Applicants'>
      <Container maxWidth='xl'>
        <Applicants />
      </Container>
    </Page>
  );
};

export default DashboardApplicants;
