import React from 'react';
import { Container } from '@mui/material';
import Page from '../components/Page';

import Opportunity from '../components/_dashboard/opportunities';

const DashboardOpportunities = () => {
  return (
    <Page title='Opportunities'>
      <Container maxWidth='xl'>
        <Opportunity />
      </Container>
    </Page>
  );
};

export default DashboardOpportunities;
