import React, { useState } from 'react';
import Table from '@mui/material/Table';
import { TableBody, TableCell, TableRow } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import AddCandidates from './AddCandidates';
import ScoreHeader from './ScoreHeader';

import { Button, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';

import { applySortFilter, getComparator } from '../../halper/TableSort';

function AllQuizTable({ data }) {
  const [assessment, setAssessment] = useState(null);
  const [score, setScore] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = data.map((ass) => {
    return {
      _id: ass._id,
      assessment: ass,
      title: ass.title,
      domain: ass.domain.title,
      candidates: ass.candidates.length,
    };
  });

  const TABLE_HEAD = [
    { id: 'title', label: 'Title', align: 'left' },
    { id: 'domain', label: 'Domain', align: 'left' },
    { id: 'candidates', label: 'Candidates', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      {score ? (
        <ScoreHeader score={score} setScore={setScore} />
      ) : (
        <>
          {assessment ? (
            <AddCandidates
              assessment={assessment}
              setAssessment={setAssessment}
            />
          ) : (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
                aria-label='table'
              >
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row' padding='none'>
                          {row.title}
                        </TableCell>
                        <TableCell align='left'> {row.domain} </TableCell>
                        <TableCell align='left'>{row.candidates} </TableCell>
                        <TableCell align='left'>
                          <Button onClick={() => setAssessment(row.assessment)}>
                            Candidates{' '}
                          </Button>{' '}
                        </TableCell>
                        <TableCell align='left'>
                          <Button onClick={() => setScore(row.assessment)}>
                            Report
                          </Button>{' '}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 50]}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </>
      )}
    </>
  );
}

export default AllQuizTable;
