import React from 'react';
import { Typography, Stack, Grid } from '@mui/material';

const PerksType = ({
  Icon,
  text,
  setFieldValue,
  fieldToUpdate,
  fieldValue,
  setPerksResponse,
}) => {
  return (
    <Grid item xs={6} sm={3} md={2}>
      <Stack
        direction='column'
        alignItems='center'
        columnSpacing={3}
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setFieldValue(`perks.${fieldToUpdate}`, !fieldValue);
          setPerksResponse('');
        }}
      >
        <Icon fontSize='large' color={fieldValue ? 'primary' : ''} />
        <Typography textAlign='center' color={fieldValue ? 'primary' : ''}>
          {text}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default PerksType;
