import React, { useState, useRef } from 'react';

import { Box, Typography, MenuItem } from '@mui/material';
import { useQueryClient } from 'react-query';
import { Formik, Form } from 'formik';
import { API_URL } from '../../../links/API_LINKS';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { SelectInput } from '../layouts/FormElement';

const StatusUpdate = ({ applicationId, status }) => {
  const [serverResponse, setServerResponse] = useState();
  const formikRef = useRef();

  const queryClient = useQueryClient();

  return (
    <div>
      <Box>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            applicationId: applicationId,
            status: status,
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setServerResponse('');
            axios({
              method: 'POST',
              url: API_URL + '/application/update-status',
              data: values,
            })
              .then(function (response) {
                setServerResponse(response.data.message);
                setSubmitting(false);
                queryClient.invalidateQueries('fetchApplications');
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {' '}
                <SelectInput
                  size='small'
                  fullWidth
                  name='status'
                  label='Status'
                >
                  <MenuItem value='Applied'>Applied</MenuItem>
                  <MenuItem value='Viewed'>Viewed</MenuItem>
                  <MenuItem value='Shortlisted'>Shortlisted</MenuItem>
                  <MenuItem value='Rejected'>Rejected</MenuItem>
                  <MenuItem value='Hired'>Hired</MenuItem>
                </SelectInput>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {/* <Button type='button' onClick={() => setIsEdit(false)}>
                    Back
                  </Button> */}
                  <LoadingButton
                    size='small'
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default StatusUpdate;
