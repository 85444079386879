import React from 'react';
import { useTheme } from '@mui/styles';
import { Link, Paper, Typography } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import LinkIcon from '@mui/icons-material/Link';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import Button from '@mui/material/Button';

const Education = ({ education }) => {
  const theme = useTheme();
  const educationDetails = education.sort(function (a, b) {
    return new Date(b.startDate) - new Date(a.startDate);
  });

  const content = educationDetails.map((ed, index) => {
    return (
      <Box mb={4} key={index}>
        <Box display='flex' justifyContent='space-between' mb={1}>
          <Box>
            <Typography variant='subtitle1'>{ed.degree}</Typography>
          </Box>
          <Box flexShrink={0}>
            <Typography variant='subtitle2' color={theme.palette.grey[500]}>
              {format(new Date(ed.startDate), 'do MMM yyy')} -{' '}
              {ed.currentlyPursuing
                ? 'Till Now'
                : format(new Date(ed.endDate), 'do MMM yyy')}
            </Typography>
          </Box>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          gap={4}
          mt={1}
          //   borderRadius={1}
          //   padding={1}
          //   sx={{ backgroundColor: theme.palette.grey[200] }}
        >
          <Typography variant='subtitle2'>{ed.fieldOfStudy}</Typography>
          <Typography variant='subtitle2'>{ed.grade}</Typography>
          <Typography>{ed.oganizationName}</Typography>
        </Box>
        {education.certificateUrl && (
          <Link
            href={ed.certificateUrl}
            underline='none'
            target='_blank'
            rel='noopener'
          >
            <Button size='small' variant='outlined' startIcon={<LinkIcon />}>
              Certificate
            </Button>
          </Link>
        )}
        <Typography variant='body2' color={theme.palette.grey[600]} mt={1}>
          {ed.description}
        </Typography>
      </Box>
    );
  });
  return (
    <Paper sx={{ padding: theme.spacing(2), height: '100%' }} elevation={2}>
      <Box
        display='flex'
        alignItems='center'
        marginTop={theme.spacing(1)}
        gap={theme.spacing(1)}
      >
        <SchoolIcon />
        <Typography variant='h5'> Education History</Typography>
      </Box>
      <Box mt={4}>{content}</Box>
    </Paper>
  );
};

export default Education;
