import React from 'react';
import { Alert, Box, CircularProgress, Grid, Typography } from '@mui/material';
import Multiselect from 'multiselect-react-dropdown';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@emotion/react';

import useEligibleSkillsData from '../../../../hooks/useEligibleSkillsData';
import DomainData from './DomainData.json';

const SelectSkill = ({ domainId, skills, setSkills }) => {
  const theme = useTheme();
  //Show selected domain from previous step
  const { name, image } = DomainData.find((domain) => domain.id === domainId);
  //Show skills to be selected associated with the domain
  const { status, data } = useEligibleSkillsData(domainId);

  function onChange(selectedList) {
    const tempSelectedList = [...selectedList];
    setSkills(tempSelectedList);
  }

  return (
    <Grid container spacing={6}>
      <Grid item sm={3}>
        <Card
          sx={{
            cursor: 'auto',
            backgroundColor: 'primary.light',
          }}
        >
          <CardContent align='center'>
            <img src={image} alt='domain' />
            <Typography variant='button' component='p' sx={{ mt: 2 }}>
              {name}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={1}></Grid>
      <Grid item sm={7}>
        <Box mx={4}>
          <Typography mb={2} variant='body2'>
            Select 3 to 6 skills for assessment
          </Typography>
          {status === 'loading' && <CircularProgress />}
          {status === 'error' && (
            <Alert severity='warning'>
              Something went wrong while fetching skills. Please try again.
            </Alert>
          )}
          {status === 'success' && (
            <Multiselect
              options={data}
              displayValue='title'
              placeholder='Select Skills'
              selectedValues={skills}
              selectionLimit={6}
              onSelect={onChange}
              onRemove={onChange}
              showArrow={true}
              style={{
                chips: {
                  // To change css chips(Selected options)
                  background: theme.palette.primary.light,
                  color: 'black',
                },
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SelectSkill;
