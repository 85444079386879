import React, { useEffect } from 'react';
import { Alert, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { TextInput } from '../../layouts/FormElement';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import { LoadingButton } from '@mui/lab';

const AddCandidates = ({
  candidates,
  setCandidates,
  domainId,
  skills,
  setValue,
}) => {
  const UpdateCandidates = () => {
    const { values } = useFormikContext();

    useEffect(() => {
      setCandidates(values.candidates);
    }, [values]);
    return null;
  };
  const queryClient = useQueryClient();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            title: '',
            domainId: domainId,
            skillIds: skills,
            candidates: [{ name: '', email: '' }],
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Quiz title is required'),
            candidates: Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required('Name is a required field'),
                email: Yup.string()
                  .email('Please enter a valid email')
                  .required('Email is a required field'),
                // Rest of your amenities object properties
              })
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const skillIds = values.skillIds.map((skill) => skill.id);
            const newData = { ...values, skillIds };
            axios({
              method: 'POST',
              url: API_URL + '/dashboard/employer/assessment',
              data: newData,
            })
              .then(function (response) {
                queryClient.invalidateQueries('fetchAssessment');
                setSubmitting(false);
                setValue(0);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ values, formik, isSubmitting }) => (
            <Form>
              <Grid item xs={12} sm={6} mb={4}>
                <Typography variant='subtitle2' mb={2}>
                  Add title of your assessment
                </Typography>
                <TextInput
                  fullWidth
                  id='outlined-basic'
                  label='Title'
                  variant='outlined'
                  placeholder='Assessment for Digital Marketing Role'
                  name='title'
                />
              </Grid>
              <FieldArray name='candidates'>
                {({ insert, remove, push }) => (
                  <>
                    {values.candidates.length > 0 &&
                      values.candidates.map((candidate, index) => (
                        <Grid container key={index} spacing={4} mb={2}>
                          <Grid item>
                            <TextInput
                              size='small'
                              type='text'
                              label='Candidate Name'
                              name={`candidates.${index}.name`}
                            />
                          </Grid>
                          <Grid item>
                            <TextInput
                              size='small'
                              type='email'
                              label='Candidate Email'
                              name={`candidates.${index}.email`}
                            />
                          </Grid>
                          <Grid item>
                            {candidates.length > 1 && (
                              <IconButton aria-label='delete' size='small'>
                                <DeleteIcon
                                  fontSize='small'
                                  onClick={() => remove(index)}
                                />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    <Button
                      variant='outlined'
                      startIcon={<AddIcon />}
                      onClick={() => push({ name: '', email: '' })}
                    >
                      Add Candidate
                    </Button>
                  </>
                )}
              </FieldArray>
              <Grid sx={{ mt: 4 }}>
                <Alert severity='info' sx={{ marginBottom: 4 }}>
                  Candidates listed above will be sent an email instructing them
                  to take the assessments and complete their Belong profile at{' '}
                  <a
                    href='https://assessment.belong.education'
                    rel='noreferrer noopener'
                    target='_blank'
                  >
                    https://assessment.belong.education
                  </a>
                </Alert>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Finish
                </LoadingButton>
              </Grid>

              <UpdateCandidates />
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default AddCandidates;
