import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../links/API_LINKS';

function fetchSkills(arg) {
  const { queryKey } = arg;
  const areaId = queryKey[1];
  const params = {};
  if (areaId) {
    Object.assign(params, { areaId });
  }
  return axios.get(API_URL + '/skills/eligible-skills', { params });
}
const useEligibleSkillsData = (areaId) => {
  return useQuery(['fetchEligibleSkills', areaId], fetchSkills, {
    select: (data) => {
      const transformedData = data.data.data.map((skill) => {
        return { id: skill._id, title: skill.title };
      });
      return transformedData;
    },
  });
};
export default useEligibleSkillsData;
