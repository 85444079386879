import { Navigate, useRoutes } from 'react-router-dom';
import { useEffect } from 'react';
// layouts
import DashboardLayout from './components/layouts/dashboard';
import LogoOnlyLayout from './components/layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import DashboardOpportunities from './pages/DashboardOpportunities';
import DashboardApplicants from './pages/DashboardApplicants';
import BelongScore from './components/_dashboard/belongScore';

import Amplify, { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { fetchEmployeDetails, signIn } from './redux';

// ----------------------------------------------------------------------
Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_h5OexVjb0',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '20738icdfn538kieuetqrajoj2',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'belong.auth.us-east-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://dashboard.belong.education',
      redirectSignOut: 'https://platform.deviare.co.za',
      clientId: '20738icdfn538kieuetqrajoj2',
      responseType: 'token',
    },
  },
});

const Router = ({ fetchEmployeDetails, authStatus, signIn }) => {
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        localStorage.setItem(
          'authorization',
          user.signInUserSession.accessToken.jwtToken
        );
        fetchEmployeDetails();
        signIn();
      })
      .catch(() => {
        console.log('Not signed in');
      });
  }, [fetchEmployeDetails, signIn]);

  return useRoutes([
    {
      path: '/dashboard',
      element: authStatus ? <DashboardLayout /> : <Navigate to='/login' />,
      children: [
        { element: <Navigate to='/dashboard/opportunities' replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'opportunities', element: <DashboardOpportunities /> },
        { path: 'applicants', element: <DashboardApplicants /> },
        { path: 'belong-score', element: <BelongScore /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to='/login' /> },
        { path: '*', element: <Navigate to='/404' /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
};

const mapStateToProps = (state) => {
  return {
    authStatus: state.auth.authStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmployeDetails: () => dispatch(fetchEmployeDetails()),
    signIn: () => dispatch(signIn()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
