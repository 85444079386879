import React, { useState } from 'react';
import AllScore from './AllScore';
import { Box } from '@mui/material';
import useScoreSummaryData from '../../../../../utils/hooks/useScoreSummaryData';

const Index = ({ userId, areas }) => {
  const [selectedDomain, setSelectedDomain] = useState(0);
  const areaId = areas[selectedDomain]?._id;
  const { status, data } = useScoreSummaryData(userId, areaId);
  //Check if enough data is there to calculate belong score
  let belongScore = 0;
  let showReport = false;
  if (status === 'success') {
    const aptitudeGiven = data.aptitude?.bestAttempt?.finalScore;
    const domainGiven = data.domain?.bestAttempt?.finalScore;
    showReport = Boolean(aptitudeGiven && domainGiven);
    const profileStrength = data.profileStrength || 0;
    const domainScore = data.domain?.bestAttempt?.percentage || 0;
    const aptitudeScore = data.aptitude?.bestAttempt?.percentage || 0;
    belongScore = Math.ceil(
      profileStrength + domainScore * 0.5 + aptitudeScore * 0.4
    );
    // setLoading(false);
  }
  return (
    <>
      {status === 'error' && 'Could not fetch belong score'}
      {showReport && (
        <Box mt={6}>
          <AllScore
            selectedDomain={selectedDomain}
            setSelectedDomain={setSelectedDomain}
            data={data}
            belongScore={belongScore}
            areas={areas}
          />
        </Box>
      )}
    </>
  );
};
export default Index;
