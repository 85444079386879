import React, { useRef, useState } from 'react';
import { TextInput, SelectInput } from '../../_dashboard/layouts/FormElement';
import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Typography, Stack } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../links/API_LINKS';
import axios from 'axios';

import { fetchCountry, fetchIndustryWithParams } from '../../../services/index';

const RegisterForm = () => {
  const [serverResponse, setServerResponse] = useState();
  const navigate = useNavigate();
  const formikRef = useRef();

  const countries = useQuery('fetchCountry', fetchCountry);
  const industries = useQuery(
    'fetchIndustry',
    fetchIndustryWithParams({ params: { limit: 0 } })
  );

  async function awsSignIn(username, password) {
    try {
      const user = await Auth.signIn(username, password);
      const token = user.signInUserSession.accessToken.jwtToken;
      localStorage.setItem('authorization', token);
      axios.defaults.headers.common['Authorization'] = token;

      navigate('/login', { replace: true });
    } catch (error) {
      navigate('/login', { replace: true });
    }
  }

  return (
    <div>
      <Typography
        sx={{ display: { md: 'none', xs: 'block' } }}
        textAlign='center'
        variant='body2'
        mb={4}
      >
        We Unlock Potential Through{' '}
        <Typography color='primary' display='inline'>
          DATA
        </Typography>{' '}
        Not{' '}
        <Typography display='inline' color='primary'>
          BIAS
        </Typography>
      </Typography>
      <Typography
        sx={{ display: { md: 'block', xs: 'none' } }}
        variant='body2'
        mb={4}
      >
        We Unlock Potential Through{' '}
        <Typography color='primary' display='inline'>
          DATA
        </Typography>{' '}
        Not{' '}
        <Typography display='inline' color='primary'>
          BIAS
        </Typography>
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          name: '',
          website: '',
          companyEmail: '',
          industryId: '',
          country: '',
          city: '',
          employeeName: '',
          employeeDesignation: '',
          password: '',
          companyPhone: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Please enter your company name'),
          website: Yup.string().url().required(),
          companyEmail: Yup.string()
            .email('Email must be a valid email address')
            .required('Email is required'),
          industryId: Yup.string().required('Please select industry'),
          country: Yup.string().required('Please select country'),
          city: Yup.string().required('Please enter your company city'),
          employeeName: Yup.string().required('Please enter your name'),
          employeeDesignation: Yup.string().required(
            'Please enter your Designation'
          ),
          companyPhone: Yup.string()
            .min(10, 'Enter Valid  Phone Number')
            .required('Please enter Phone Number'),
          password: Yup.string()
            .required('Password is required')
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              'Password must contain at least 8 characters, one uppercase, one number and one special case character'
            ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // setSubmitting(false);
          // navigate('/dashboard', { replace: true });

          setServerResponse('');
          axios({
            method: 'POST',
            url: API_URL + '/dashboard/employer/add-employee',
            data: values,
          })
            .then(function (response) {
              awsSignIn(values.companyEmail, values.password);

              //setServerResponse(response.data.message);
              // setSubmitting(false);
            })
            .catch(function (error) {
              setServerResponse(error.response.data.message);
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='name'
                  type='text'
                  label='Company Name'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='website'
                  type='text'
                  label='Company Website'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='city'
                  type='text'
                  label='City'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='country'
                  label='Country'
                >
                  {countries.status === 'success' &&
                    Object.entries(countries.data.data.data).map(
                      ([countryCode, countryData]) => {
                        return (
                          <MenuItem key={countryCode} value={countryData.name}>
                            {countryData.name}
                          </MenuItem>
                        );
                      }
                    )}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='industryId'
                  label='Industry'
                >
                  {industries.status === 'success' &&
                    industries.data.data.data.map((indistry) => {
                      return (
                        <MenuItem key={indistry._id} value={indistry._id}>
                          {indistry.title}
                        </MenuItem>
                      );
                    })}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='employeeName'
                  type='text'
                  label='Employee Name'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='employeeDesignation'
                  type='text'
                  label='Employee Designation'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneInput
                  name='companyPhone'
                  value={values.phone}
                  onChange={(phone) => setFieldValue('companyPhone', phone)}
                  preferredCountries={['in']}
                  placeholder='Phone No.'
                  country={'in'}
                />
                <Typography color={'red'} variant='caption'>
                  {errors.companyPhone}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='companyEmail'
                  type='text'
                  label='Work Email'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='password'
                  type='password'
                  label='Password'
                />
              </Grid>
            </Grid>

            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Register
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterForm;
