import React from 'react';
import { API_URL } from '../../../../links/API_LINKS';
import { useQuery } from 'react-query';
import axios from 'axios';
import AllQuizTable from './AllQuizTable';
import { CircularProgress, Box } from '@mui/material';

const Index = () => {
  const fetchAssessment = async () => {
    const res = await axios.get(API_URL + '/dashboard/employer/assessment');
    return res;
  };
  const { status, data } = useQuery('fetchAssessment', fetchAssessment);

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'success' && <AllQuizTable data={data.data.data} />}
    </div>
  );
};

export default Index;
