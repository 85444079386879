import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Modal, Box } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import EditRole from './EditRole';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '90%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function RolesBody({ row }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component='th' scope='row' padding='none'>
          {row.roleTitle}
        </TableCell>
        <TableCell align='left'>{row.roleType} </TableCell>
        <TableCell align='left'> {row.domain} </TableCell>
        <TableCell align='left'>
          {' '}
          {format(new Date(row.cutoffDate), 'do LLLL yyyy')}{' '}
        </TableCell>
        <TableCell align='left'>{row.status} </TableCell>
        <TableCell align='center'>
          <a
            target='_blank'
            rel='noreferrer'
            href={`https://dashboard.belong.education/jobs/${row.slug}`}
          >
            <PreviewIcon color='primary' />
          </a>
        </TableCell>
        <TableCell>
          <IconButton size='small' onClick={handleOpen}>
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EditRole handleClose={handleClose} role={row.allDetails} />
        </Box>
      </Modal>
    </>
  );
}

export default RolesBody;
