import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DvrIcon from '@mui/icons-material/Dvr';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SchoolIcon from '@mui/icons-material/School';
import FestivalIcon from '@mui/icons-material/Festival';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import SickIcon from '@mui/icons-material/Sick';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import PerksType from './PerksType';

const Perks = ({ setFieldValue, perksValues, setPerksResponse }) => {
  return (
    <Box>
      <Typography variant='subtitle2' textAlign='center' mt={2} mb={1}>
        Perks and Benefits
      </Typography>
      <Grid container spacing={2} mb={3}>
        <PerksType
          Icon={HomeIcon}
          text='Work From Home'
          setFieldValue={setFieldValue}
          fieldToUpdate='workFromHome'
          fieldValue={perksValues.workFromHome}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={DvrIcon}
          text='Job Trainings'
          setFieldValue={setFieldValue}
          fieldToUpdate='jobTrainings'
          fieldValue={perksValues.jobTrainings}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={DirectionsCarIcon}
          text='Transport'
          setFieldValue={setFieldValue}
          fieldToUpdate='transport'
          fieldValue={perksValues.transport}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={LocalHospitalIcon}
          text='Health Insurance'
          setFieldValue={setFieldValue}
          fieldToUpdate='healthInsurance'
          fieldValue={perksValues.healthInsurance}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={SchoolIcon}
          text='Education Assisstance'
          setFieldValue={setFieldValue}
          fieldToUpdate='educationAssisstance'
          fieldValue={perksValues.educationAssisstance}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={FestivalIcon}
          text='Team Outings'
          setFieldValue={setFieldValue}
          fieldToUpdate='teamOutings'
          fieldValue={perksValues.teamOutings}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={CorporateFareIcon}
          text='Corporate Discounts'
          setFieldValue={setFieldValue}
          fieldToUpdate='corporateDiscounts'
          fieldValue={perksValues.corporateDiscounts}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={HolidayVillageIcon}
          text='Paid Vacation'
          setFieldValue={setFieldValue}
          fieldToUpdate='paidVacation'
          fieldValue={perksValues.paidVacation}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={SickIcon}
          text='Paid Sick Leaves'
          setFieldValue={setFieldValue}
          fieldToUpdate='paidSickLeaves'
          fieldValue={perksValues.paidSickLeaves}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={AttachMoneyIcon}
          text='Performance Bonus'
          setFieldValue={setFieldValue}
          fieldToUpdate='performanceBonus'
          fieldValue={perksValues.performanceBonus}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={ShowChartIcon}
          text='Stock Options'
          setFieldValue={setFieldValue}
          fieldToUpdate='stockOptions'
          fieldValue={perksValues.stockOptions}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={BabyChangingStationIcon}
          text='Paid Parental Leaves'
          setFieldValue={setFieldValue}
          fieldToUpdate='paidParentalLeaves'
          fieldValue={perksValues.paidParentalLeaves}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={FamilyRestroomIcon}
          text='Childcare Facilities'
          setFieldValue={setFieldValue}
          fieldToUpdate='childcareFacilities'
          fieldValue={perksValues.childcareFacilities}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={KeyboardIcon}
          text='Office Perks'
          setFieldValue={setFieldValue}
          fieldToUpdate='officePerks'
          fieldValue={perksValues.officePerks}
          setPerksResponse={setPerksResponse}
        />
        <PerksType
          Icon={SentimentSatisfiedAltIcon}
          text='Wellness Programs'
          setFieldValue={setFieldValue}
          fieldToUpdate='wellnessPrograms'
          fieldValue={perksValues.wellnessPrograms}
          setPerksResponse={setPerksResponse}
        />
      </Grid>
    </Box>
  );
};

export default Perks;
