import React, { useState } from 'react';
import * as Yup from 'yup';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Auth } from 'aws-amplify';
// material
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const ResetPassword = ({ setCodeSent, setEnteredEmail, setResetPage }) => {
  const [feedbackMessage, setFeedbackMessage] = useState();
  const SendCodeSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: SendCodeSchema,
    onSubmit: async () => {
      try {
        await Auth.forgotPassword(values.email);
        setCodeSent(true);
        setEnteredEmail(values.email);
      } catch (error) {
        setFeedbackMessage(error.messaage);
      }
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <>
        <Stack sx={{ mb: 5 }}>
          <Typography variant='h4' gutterBottom>
            Reset Your Password!
          </Typography>
          <Typography variant='caption' sx={{ color: 'text.secondary' }}>
            Please click on Reset Password button to send a confirmation code to
            your email address and create a new password for your Belong
            account.
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ my: 2 }}>
              <TextField
                fullWidth
                autoComplete='username'
                type='email'
                label='Email address'
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
            >
              Send Code
            </LoadingButton>
          </Form>
          <Stack>{feedbackMessage}</Stack>
        </FormikProvider>
        <Typography
          variant='subtitle2'
          gutterBottom
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'right',
            cursor: 'pointer',
          }}
          onClick={() => setResetPage(false)}
        >
          Back
        </Typography>
      </>
    </>
  );
};

export default ResetPassword;
