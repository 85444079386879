import api from './api';

const fetchSkills = async ({ params, headers }) => {
  const response = await api.get({ url: '/skill', params, headers });
  return response;
};

const fetchSkillsWithParams = (functionArgs = {}) => {
  const { params, headers } = functionArgs;
  return async () => {
    const requestObject = { url: 'v2/skills' };
    if (params) requestObject.params = params;
    if (headers) requestObject.headers = params;
    const response = await api.get(requestObject);
    return response;
  };
};

export { fetchSkills, fetchSkillsWithParams };
