import { Grid, Typography } from '@mui/material';
import React from 'react';
import NewProfile from '../../applicants/NewProfile';
import ScoreBar from '../../applicants/profile/belongscorereport/ScoreBar';
import ScoreChart from '../../applicants/profile/belongscorereport/ScoreChart';

const Profile = ({ setShowProfile, scoreDetails }) => {
  function calculatebelongScore(data) {
    const profileStrength = data?.profileStrength || 0;
    const domainScore = data?.domain?.bestAttempt?.percentage || 0;
    const aptitudeScore = data?.aptitude?.bestAttempt?.percentage || 0;
    const belongScore = Math.ceil(
      profileStrength + domainScore * 0.5 + aptitudeScore * 0.4
    );
    return belongScore;
  }

  const testsGiven =
    Boolean(scoreDetails?.aptitude?.bestAttempt) &&
    Boolean(scoreDetails?.domain?.bestAttempt);
  return (
    <div>
      <NewProfile
        setShowProfile={setShowProfile}
        userId={scoreDetails.userId}
        hideBelongScore={true}
      />
      {testsGiven && (
        <>
          <Grid container spacing={4} mt={2}>
            <Grid item sm={12} md={3} align='center'>
              <Typography variant='h6' sx={{ color: 'primary.main' }}>
                Belong Score :{' '}
                <Typography
                  variant='h6'
                  sx={{ color: 'black', display: 'inline' }}
                >
                  {calculatebelongScore(scoreDetails)}
                </Typography>
              </Typography>
              <Typography variant='body2'>
                Employability Proof powered by Belong
              </Typography>
            </Grid>
            <Grid item sm={12} md={9} align='center'>
              <ScoreBar />
            </Grid>
          </Grid>
          <ScoreChart data={scoreDetails} />
        </>
      )}
    </div>
  );
};

export default Profile;
