import React from 'react';
import Registration from './Registration';
//import FormStepper from './FormStepper';
// import ActiveRecruiter from './ActiveRecruiter';
// import FinalStep from './finalStep';

const Index = ({ companyId, setValue }) => {
  //const [currentStep, setCurrentStep] = useState(0);
  return (
    <div>
      {/* <FormStepper currentStep={currentStep} /> */}
      {/* {currentStep === 0 && <Registration setCurrentStep={setCurrentStep} />}
      {currentStep === 1 && <ActiveRecruiter setCurrentStep={setCurrentStep} />}
      {currentStep === 2 && <FinalStep setCurrentStep={setCurrentStep} />} */}
      <Registration companyId={companyId} setValue={setValue} />
    </div>
  );
};

export default Index;
