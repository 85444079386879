import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import Roles from './roles';
import CreateRole from './createRole';
import { connect } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Index = ({ employe }) => {
  const companyId = employe.data.companyId._id;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='opportunity'
          variant='scrollable'
          scrollButtons='auto'
        >
          <Tab label='Roles' {...a11yProps(0)} />
          <Tab label='Create New Role' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Roles companyId={companyId} setValue={setValue} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CreateRole companyId={companyId} setValue={setValue} />
      </TabPanel>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    employe: state.employe.employe,
  };
};
export default connect(mapStateToProps)(Index);
