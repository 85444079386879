import api from './api';

const awsUpload = async ({ data, params, headers }) => {
  const response = await api.post({
    url: '/aws-upload/single',
    params,
    data,
    headers: { 'Content-Type': 'multipart/form-data', ...headers },
  });
  return response;
};

export { awsUpload };
