import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box } from '@mui/material';
// layouts
import AuthLayout from '../components/layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { RegisterForm } from '../components/authentication/register';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(0, 0, 0, 0),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 780,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 0),
}));

// ----------------------------------------------------------------------
export default function Register() {
  return (
    <RootStyle title='Register'>
      <AuthLayout>
        Already have an account? &nbsp;
        <Link
          underline='none'
          variant='subtitle2'
          component={RouterLink}
          to='/login'
        >
          Login
        </Link>
      </AuthLayout>

      <SectionStyle>
        <Box>
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
            <img
              alt='register'
              src='https://res.cloudinary.com/belong/image/upload/v1655894492/uploaded_resources/Group_2_1_pb0mtd.png'
            />
          </Box>
        </Box>
        <Typography textAlign='center'></Typography>
      </SectionStyle>

      <Container>
        <ContentStyle>
          <RegisterForm />

          <MHidden width='smUp'>
            <Typography variant='subtitle2' sx={{ mt: 3, textAlign: 'center' }}>
              Already have an account?&nbsp;
              <Link to='/login' component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
