import React, { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../layouts/TableListHead';
import ApplicantBody from './ApplicantBody';

import { applySortFilter, getComparator } from '../halper/TableSort';
import { parseInt } from 'lodash';

function ApplicantHeader({ data, setShowProfile }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('applicantName');
  const [searched, setSearched] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows_ = useMemo(() => {
    return data.map((user) => {
      const percentageScore =
        user.userId.assessmentData?.percentageScore ||
        user.userId.masterclassAssessment?.percentageScore;
      let score = 0;
      if (percentageScore) {
        score = parseInt(
          Object.values(percentageScore).reduce((a, b) => a + b, 0) / 4
        );
      }
      return {
        _id: user._id,
        userId: user.userId._id,
        applicantName: user.userId.firstName || user.userId.name || '',
        roleName: user.opportunityId.roleDetails.roleTitle,
        appliedOn: user.appliedOn,
        emailAddress: user.userId.email,
        status: user.currentStatus,
        empScore: score,
        area: user.userId.areas[0],
      };
    });
  }, [data]);

  const TABLE_HEAD = [
    {
      id: 'applicantName',
      numeric: false,
      disablePadding: true,
      label: 'Applicant Name',
      align: 'left',
    },
    {
      id: 'roleName',
      numeric: false,
      disablePadding: true,
      label: 'Role Name',
      align: 'left',
    },
    {
      id: 'appliedOn',
      numeric: false,
      disablePadding: true,
      label: 'Applied On',
      align: 'left',
    },
    {
      id: 'emailAddress',
      numeric: false,
      disablePadding: true,
      label: 'Email Address',
      align: 'left',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      align: 'left',
    },
    {
      id: 'empScore',
      numeric: true,
      disablePadding: false,
      label: 'Employability Score',
      align: 'left',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: 'Actions',
      align: 'left',
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const temp = [...rows_];
  const [applicantdata, setApplicantData] = useState(temp);

  useEffect(() => {
    setApplicantData(rows_);
  }, [rows_]);

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    const searchedValLower = searchedVal.toLowerCase();
    const filteredRows = rows_.filter((row) => {
      return row.status.toLowerCase().includes(searchedValLower);
    });
    setApplicantData(filteredRows);
  };

  const filteredUsers = applySortFilter(
    applicantdata,
    getComparator(order, orderBy)
  );

  return (
    <>
      {rows_.length ? (
        <>
          {' '}
          <Box sx={{ minWidth: 120, maxWidth: 180 }}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Select Applicant Status
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={searched}
                label='Select Applicant Status'
                onChange={(e) => requestSearch(e.target.value)}
              >
                <MenuItem value=''>All</MenuItem>
                <MenuItem value='Applied'>New</MenuItem>
                <MenuItem value='Viewed'>Viewed</MenuItem>
                <MenuItem value='Shortlisted'>Shortlisted</MenuItem>
                <MenuItem value='Rejected'>Rejected</MenuItem>
                <MenuItem value='Hired'>Hired</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
              aria-label='table'
            >
              <TableListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows_.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <ApplicantBody
                      key={row._id}
                      row={row}
                      setShowProfile={setShowProfile}
                    />
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 50]}
              component='div'
              count={filteredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      ) : (
        <Box>
          <Typography my={4}>No Applicant found yet</Typography>
        </Box>
      )}
    </>
  );
}

export default ApplicantHeader;
