import React from 'react';
import DomainData from './DomainData.json';
import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function DomainCard({ active, img, name, onClick }) {
  return (
    <Card
      onClick={onClick}
      sx={{
        width: '20%',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'primary.light',
        },
        '&.active': {
          backgroundColor: 'primary.main',
        },
      }}
      className={active ? 'active' : ''}
    >
      <CardContent align='center'>
        <img src={img} alt='domain' />
        <Typography variant='button' component='p' sx={{ mt: 2 }}>
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
}

const SelectDomain = ({ domainId, setDomainId, setSkills, handleNext }) => {
  return (
    <div>
      <Stack direction='row' justifyContent='center' spacing={4}>
        {DomainData.map((domain) => {
          return (
            <DomainCard
              key={domain.id}
              img={domain.image}
              name={domain.name}
              active={domainId === domain.id}
              onClick={() => {
                setDomainId(domain.id);
                handleNext();
                //If domain is changed it will reset skills in the next step
                setSkills([]);
              }}
            />
          );
        })}
      </Stack>
    </div>
  );
};

export { SelectDomain, DomainCard };
