import React, { useRef, useState } from 'react';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import { useQuery, useQueryClient } from 'react-query';
import { UPLOAD_OR_DOWNLOAD_URL, API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';
import { City } from 'country-state-city';
import { LoadingButton } from '@mui/lab';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Stack,
  Link,
  InputAdornment,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import AddJobDetails from '../createRole/AddJobDetails';
import Perks from '../createRole/Perks';

import Multiselect from 'multiselect-react-dropdown';

import {
  fetchCountry,
  fetchAreasWithParams,
  awsUpload,
  fetchSkillsWithParams,
} from '../../../../services/index';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  overflow: 'scroll',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EditRole = ({ role, handleClose }) => {
  const [allCity, setAllCity] = useState(
    City.getCitiesOfCountry('IN').map((myCity) => {
      return myCity.name;
    })
  );
  const [perksResponse, setPerksResponse] = useState('');
  const [serverResponse, setServerResponse] = useState();
  const [fileloading, setFileloading] = useState(false);
  const formikRef = useRef();
  const [open, setOpen] = React.useState(false);
  const areas = useQuery(
    'fetchAreas',
    fetchAreasWithParams({ params: { limit: 0 } })
  );
  const skills = useQuery(
    'fetchSkillsWithParams',
    fetchSkillsWithParams({ params: { limit: 0 } })
  );
  const countries = useQuery('fetchCountry', fetchCountry);
  const handleUploadFile = async ({ files, setFieldValue, item }) => {
    try {
      setFileloading(true);
      const formData = new FormData();
      if (files.length !== 0) {
        formData.append('file', files[0]);
        formData.append('fileFormat', 'pdf');
        formData.append('uploadingAs', 'JOB_DESCRIPTION');
        formData.append('path', 'resources/job-description');
      }
      const result = await awsUpload({ data: formData });
      if (result.status === 200) {
        setFileloading(false);
        setFieldValue(item, result.data.publicUrl);
        setServerResponse('');
      }
    } catch {
      setServerResponse('Error! Could not process your request.');
      setFileloading(false);
    }
  };
  const updateCity = (country) => {
    if (countries.status === 'success') {
      const myObj = Object.entries(countries.data.data.data).filter(
        ([countryCode, countryData]) => {
          return countryData.name === country;
        }
      );
      const myCode = myObj[0] && myObj[0][0];
      const myAllCities = City.getCitiesOfCountry(myCode).map((myCity) => {
        return myCity.name;
      });
      setAllCity(myAllCities);
    }
  };

  const queryClient = useQueryClient();
  const jobDescriptionText = {
    time: 1656407933507,
    blocks: [
      {
        id: 'JnoKiRdfrv',
        type: 'paragraph',
        data: {
          text: '<b>Job Description</b><br>',
        },
      },
      {
        id: 'a2I1mthqkA',
        type: 'paragraph',
        data: {
          text: '<b>Job Highlights</b>',
        },
      },
    ],
    version: '2.25.0',
  };
  return (
    <div>
      <Typography my={4}>
        Fill in the details below to become an
        <span style={{ color: '#10ABB7' }}> Active Recruiter</span>
      </Typography>
      <Modal open={open} aria-labelledby='modal' aria-describedby='description'>
        <Box sx={style}>
          <AddJobDetails setOpen={setOpen} formikRef={formikRef} />
        </Box>
      </Modal>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          opportunityId: role._id,
          companyId: role.companyId._id,
          domainId: role.domainId._id,
          roleDetails: role.roleDetails || {
            roleTitle: '',
            roleType: 'Internship',
            requiredExperience: '0-0.5 Year',
          },
          jobType: role.jobType,
          openPositionsCount: role.openPositionsCount,
          jobDescription:
            role.jobDescription && role.jobDescription.blocks
              ? role.jobDescription
              : jobDescriptionText,
          postInternshipHiring: role.postInternshipHiring,
          cutoffDate: role.cutoffDate,
          selectionProcess: role.selectionProcess,
          duration: role.duration,
          jobDescriptionDoc: role.jobDescriptionDoc,
          jobLocation: role.jobLocation || { country: '', city: [] },
          payRange: role.payRange || {
            disclosed: true,
            currency: 'INR',
            minimum: '',
            maximum: '',
          },
          status: role.status,
          perks: role.perks || {},
          skillId: role.skillId ? role.skillId.map((skill) => skill._id) : [],
        }}
        validationSchema={Yup.object({
          roleDetails: Yup.object().shape({
            roleTitle: Yup.string().required('Role title is required'),
          }),
          domainId: Yup.string().required('Domain is required'),
          openPositionsCount: Yup.number().required(
            'Number of Candidates is required'
          ),
          jobLocation:
            formikRef.current &&
            formikRef.current.values.jobType === 'On-site' &&
            Yup.object().shape({
              country: Yup.string().required('Country is required'),
            }),
          payRange: Yup.object().shape({
            minimum: Yup.number().required('Minimum CTC is required'),
            maximum: Yup.number().required('Maximum CTC is required'),
          }),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          let myData = { ...values };
          if (values.jobType === 'Remote') {
            myData.jobLocation = { country: '', city: [] };
          }
          if (values.roleDetails.roleType === 'Full Time') {
            myData = { ...myData, duration: '', postInternshipHiring: false };
          }
          const obj = values.perks;
          const totalPerks = Object.values(obj).filter((e) => e).length;
          if (totalPerks < 3 || totalPerks > 9) {
            setPerksResponse(
              'Please select minimum 3 and maximum 9 Perks and Benefits'
            );
            setSubmitting(false);
          } else {
            if (myData.jobDescription.blocks.length < 3) {
              setSubmitting(false);
              setOpen(true);
            } else {
              axios({
                method: 'PUT',
                url: API_URL + '/opportunities',
                data: myData,
              })
                .then(function (response) {
                  setServerResponse('Opportunity added successfully');
                  setSubmitting(false);
                  resetForm();
                  queryClient.invalidateQueries('fetchOpportunities');
                  handleClose();
                })
                .catch(function (response) {
                  setServerResponse('Error! Could not process your request.');
                  setSubmitting(false);
                });
            }
          }
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='domainId'
                  label='Select the Domain'
                >
                  <MenuItem value=''>Select the Domain</MenuItem>
                  {areas.status === 'success' &&
                    areas.data.data.data.map((area) => {
                      return (
                        <MenuItem key={area._id} value={area._id}>
                          {area.title}
                        </MenuItem>
                      );
                    })}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction='row' spacing={2}>
                  <Typography> Select Skills </Typography>
                  {skills.status === 'success' && (
                    <Multiselect
                      isObject={true}
                      displayValue='name'
                      options={skills.data.data.data.map((skill) => {
                        return { name: skill.title, id: skill._id };
                      })}
                      selectedValues={skills.data.data.data
                        .filter((skill) => {
                          return values.skillId.includes(skill._id);
                        })
                        .map((skillname) => {
                          return { name: skillname.title, id: skillname._id };
                        })}
                      onSelect={(selectedList, selectedItem) => {
                        let mySkills = values.skillId;
                        mySkills.push(selectedItem.id);
                        setFieldValue('skillId', mySkills);
                      }}
                      onRemove={(selectedList, removedItem) => {
                        const mySkill = values.skillId.filter((skill) => {
                          return removedItem.id !== skill;
                        });
                        setFieldValue('skillId', mySkill);
                      }}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='roleDetails.roleTitle'
                  type='text'
                  label='Enter the role title'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='roleDetails.roleType'
                  label='Role Type'
                >
                  <MenuItem value='Internship'>Internship</MenuItem>

                  <MenuItem value='Full Time'>Full Time</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type='button'
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Edit Job Description
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                {values.jobDescriptionDoc ? (
                  <Link
                    href={
                      UPLOAD_OR_DOWNLOAD_URL + '/' + values.jobDescriptionDoc
                    }
                    target='_blank'
                    rel='noreferrer'
                    underline='hover'
                  >
                    <AttachFileIcon />
                    your file
                  </Link>
                ) : (
                  <Button
                    startIcon={<CloudUploadIcon />}
                    type='button'
                    variant='outlined'
                    component='label'
                    disabled={fileloading}
                  >
                    Upload Job Description
                    <input
                      type='file'
                      accept='application/pdf'
                      hidden
                      onChange={(e) =>
                        handleUploadFile({
                          files: e.target.files,
                          setFieldValue,
                          item: 'jobDescriptionDoc',
                        })
                      }
                    />
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='openPositionsCount'
                  type='number'
                  label='Enter Number of Candidates'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  // locale='frLocale'
                >
                  <DatePicker
                    // mask='__/__/____'
                    label='Cut off date'
                    value={values.cutoffDate}
                    onChange={(newValue) => {
                      setFieldValue('cutoffDate', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size='small' fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              {values.roleDetails.roleType === 'Internship' && (
                <Grid item xs={12} sm={12}>
                  <RadioGroup
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    defaultValue={values.postInternshipHiring}
                  >
                    <Typography mt={1} mr={2} variant='subtitle2'>
                      Possibility of hring post internship ?
                    </Typography>
                    <FormControlLabel
                      onChange={(e) => {
                        setFieldValue(
                          'postInternshipHiring',
                          e.target.value === 'true' ? true : false
                        );
                      }}
                      value={true}
                      control={<Radio size='small' />}
                      label='Yes'
                    />
                    <FormControlLabel
                      onChange={(e) => {
                        setFieldValue(
                          'postInternshipHiring',
                          e.target.value === 'true' ? true : false
                        );
                      }}
                      value={false}
                      control={<Radio size='small' />}
                      label='No'
                    />
                  </RadioGroup>
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <RadioGroup
                  sx={{ display: 'flex', flexDirection: 'row' }}
                  defaultValue={values.jobType}
                >
                  <Typography mt={1} mr={2} variant='subtitle2'>
                    Work culture
                  </Typography>
                  <FormControlLabel
                    onChange={(e) => {
                      setFieldValue('jobType', e.target.value);
                    }}
                    value='On-site'
                    control={<Radio size='small' />}
                    label='Onsite'
                  />
                  <FormControlLabel
                    onChange={(e) => {
                      setFieldValue('jobType', e.target.value);
                    }}
                    value='Hybrid'
                    control={<Radio size='small' />}
                    label='Hybrid'
                  />
                  <FormControlLabel
                    onChange={(e) => {
                      setFieldValue('jobType', e.target.value);
                    }}
                    value='Remote'
                    control={<Radio size='small' />}
                    label='Remote'
                  />
                </RadioGroup>
              </Grid>

              {values.jobType !== 'Remote' && (
                <Grid item xs={12} sm={6}>
                  <SelectInput
                    fullWidth
                    size='small'
                    name='jobLocation.country'
                    label='Country'
                    onBlur={() => updateCity(values.jobLocation.country)}
                  >
                    {countries.status === 'success' &&
                      Object.entries(countries.data.data.data).map(
                        ([countryCode, countryData]) => {
                          return (
                            <MenuItem
                              key={countryCode}
                              value={countryData.name}
                            >
                              {countryData.name}
                            </MenuItem>
                          );
                        }
                      )}
                  </SelectInput>
                </Grid>
              )}

              {values.jobType !== 'Remote' && (
                <Grid item xs={12} sm={6}>
                  <Stack direction='row' spacing={2}>
                    <Typography> Select City </Typography>
                    <Multiselect
                      isObject={false}
                      options={allCity}
                      selectedValues={values.jobLocation.city}
                      onSelect={(selectedList, selectedItem) => {
                        setFieldValue('jobLocation.city', selectedList);
                      }}
                      onRemove={(selectedList, removedItem) => {
                        setFieldValue('jobLocation.city', selectedList);
                      }}
                    />
                  </Stack>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <RadioGroup
                  sx={{ display: 'flex', flexDirection: 'row' }}
                  defaultValue={values.payRange.disclosed}
                >
                  <Typography mt={1} mr={2} variant='subtitle2'>
                    Disclosed Salary
                  </Typography>
                  <FormControlLabel
                    onChange={(e) => {
                      setFieldValue(
                        'payRange.disclosed',
                        e.target.value === 'true' ? true : false
                      );
                    }}
                    value={true}
                    control={<Radio size='small' />}
                    label='Yes'
                  />
                  <FormControlLabel
                    onChange={(e) => {
                      setFieldValue(
                        'payRange.disclosed',
                        e.target.value === 'true' ? true : false
                      );
                    }}
                    value={false}
                    control={<Radio size='small' />}
                    label='No'
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='payRange.currency'
                  label='Currency'
                >
                  <MenuItem value='INR'>INR</MenuItem>
                  <MenuItem value='USD'>USD</MenuItem>
                  <MenuItem value='EUR'>EUR</MenuItem>
                </SelectInput>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  // fullWidth
                  name='payRange.minimum'
                  type='number'
                  label='Minimum compensation'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>/ Year</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  // fullWidth
                  name='payRange.maximum'
                  type='number'
                  label='maximum compensation'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>/ Year</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {values.roleDetails.roleType === 'Internship' && (
                <Grid item xs={12} sm={6}>
                  <SelectInput
                    size='small'
                    fullWidth
                    name='duration'
                    label='Duration of work'
                  >
                    <MenuItem value='1 Month'>1 Month</MenuItem>
                    <MenuItem value='3 Months'>3 Months</MenuItem>
                    <MenuItem value='6 Months'>6 Months</MenuItem>
                    <MenuItem value='1 Year'>1 Year</MenuItem>
                  </SelectInput>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='selectionProcess'
                  label='Selection Process'
                >
                  <MenuItem value='Interview Only'>Interview Only</MenuItem>
                  <MenuItem value='Assessment + Interview'>
                    Assessment + Interview
                  </MenuItem>
                  <MenuItem value='Assessment + 2 Round of Interview'>
                    Assessment + 2 Round of Interview
                  </MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='roleDetails.requiredExperience'
                  label='Required Experience'
                >
                  <MenuItem value='0-0.5 Year'>0-0.5 Year </MenuItem>
                  <MenuItem value='0.5-3 Year'>0.5-3 Years</MenuItem>
                  <MenuItem value='3-6 Year'>3-6 Years</MenuItem>
                  <MenuItem value='6+ Year'>6+Years </MenuItem>
                </SelectInput>
              </Grid>
            </Grid>
            <Perks
              setPerksResponse={setPerksResponse}
              setFieldValue={setFieldValue}
              perksValues={values.perks}
            />
            <Typography textAlign='center' color='error'>
              {perksResponse}
            </Typography>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Update
              </LoadingButton>
              {/* <Button type='button' variant='outlined'>
                Cancel
              </Button> */}
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditRole;
