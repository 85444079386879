import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { API_URL } from '../../../../links/API_LINKS';
import { useQuery } from 'react-query';
import axios from 'axios';

const ScoreBody = ({ row, employersAssessmentId, setShowProfile }) => {
  const fetchBelongScore = async () => {
    const res = await axios.get(
      API_URL + '/user/profile/employers-score-summary',
      {
        params: {
          employersAssessmentId,
          email: row.email,
        },
      }
    );
    return res;
  };
  const { status, data } = useQuery(
    `fetchBelongScore${row.email}-${employersAssessmentId}`,
    fetchBelongScore,
    { select: (data) => data?.data?.data }
  );

  function calculatebelongScore(data) {
    const profileStrength = data?.profileStrength || 0;
    const domainScore = data?.domain?.bestAttempt?.percentage || 0;
    const aptitudeScore = data?.aptitude?.bestAttempt?.percentage || 0;
    const belongScore = Math.ceil(
      profileStrength + domainScore * 0.5 + aptitudeScore * 0.4
    );
    return belongScore;
  }

  return (
    <>
      <TableRow
        onClick={() => data?.profileStrength && setShowProfile(data)}
        sx={{ cursor: data?.profileStrength ? 'pointer' : '' }}
      >
        <TableCell component='th' scope='row' padding='none'>
          {row.name}
        </TableCell>
        <TableCell align='left'> {row.email} </TableCell>
        <TableCell align='left'>
          {status === 'success' && data?.profileStrength
            ? `${data?.profileStrength}/10`
            : '-'}
        </TableCell>
        <TableCell align='left'>
          {status === 'success' &&
            (data?.aptitude?.bestAttempt
              ? `${data?.aptitude?.bestAttempt?.percentage} %`
              : '-')}
        </TableCell>
        <TableCell align='left'>
          {status === 'success' &&
            (data?.domain?.bestAttempt
              ? `${data?.domain?.bestAttempt?.percentage} %`
              : '-')}
        </TableCell>
        <TableCell align='left'>
          {status === 'success' &&
            (data?.profileStrength ? `${calculatebelongScore(data)} %` : '-')}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ScoreBody;
