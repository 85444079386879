import api from './api';

const fetchCountry = async ({ params, headers }) => {
  const response = await api.get({
    url: 'v2/countrytimezones/countries',
    params,
    headers,
  });
  return response;
};

export { fetchCountry };
