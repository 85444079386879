import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { Box, Button, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import RolesBody from './RolesBody';

import { applySortFilter, getComparator } from '../../halper/TableSort';

function RolesHeader({ data, setValue }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('roleTitle');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = data.map((role) => {
    return {
      _id: role._id,
      roleTitle: role.roleDetails.roleTitle,
      roleType: role.roleDetails.roleType,
      domain: role.domainId.title,
      status: role.status,
      slug: role.slug,
      cutoffDate: role.cutoffDate,
      allDetails: role,
    };
  });

  const TABLE_HEAD = [
    { id: 'roleTitle', label: 'Roles', align: 'left' },
    { id: 'roleType', label: 'Role Type', align: 'left' },
    { id: 'domain', label: 'Domain', align: 'left' },
    { id: 'cutoffDate', label: 'Cut-off Date', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'edit', label: 'View Job', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      {rows.length ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
            aria-label='table'
          >
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <RolesBody key={row._id} row={row} />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 50]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <Box>
          <Typography my={4}>
            No jobs/ internships posted yet. Add a role to become an
            <span style={{ color: '#10ABB7' }}> Active Recruiter</span>
          </Typography>
          <Typography my={1}>
            - To generate a continuous pipeline of profiles qualified candidates
          </Typography>
          <Button
            onClick={() => {
              setValue(1);
            }}
          >
            Add Role
          </Button>
        </Box>
      )}
    </>
  );
}

export default RolesHeader;
