import { SIGN_IN, SIGN_OUT } from './authTypes';

export const signIn = (userObject) => {
  return {
    type: SIGN_IN,
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};
