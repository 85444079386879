import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const SidebarConfig = [
  // {
  //   title: 'Home',
  //   path: '/dashboard/app',
  //   icon: getIcon(pieChart2Fill),
  // },
  {
    title: 'Opportunities',
    path: '/dashboard/opportunities',
    icon: getIcon(peopleFill),
  },
  {
    title: 'Applicants',
    path: '/dashboard/applicants',
    icon: getIcon(fileTextFill),
  },
  {
    title: 'Belong Score',
    path: '/dashboard/belong-score',
    icon: getIcon(pieChart2Fill),
  },
];

export default SidebarConfig;
