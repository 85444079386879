import React from 'react';
import { useTheme } from '@mui/styles';
import { Link, Paper, Typography } from '@mui/material';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import LinkIcon from '@mui/icons-material/Link';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import Button from '@mui/material/Button';

const ProjectDetails = ({ projectPortfolio }) => {
  const theme = useTheme();
  const project = projectPortfolio.sort(function (a, b) {
    return new Date(b.startDate) - new Date(a.startDate);
  });

  const content = project.map((project, index) => {
    return (
      <Box mb={4} key={index}>
        <Box display='flex' justifyContent='space-between' mb={1}>
          <Box>
            <Typography variant='subtitle1'>{project.projectName}</Typography>
            {/* <Typography>{work.companyName}</Typography> */}
          </Box>
          <Box>
            <Typography variant='subtitle2' color={theme.palette.grey[500]}>
              {format(new Date(project.startDate), 'do MMM yyy')} -{' '}
              {project.currentlyWorking
                ? 'Till Now'
                : project.endDate &&
                  format(new Date(project.endDate), 'do MMM yyy')}
            </Typography>
          </Box>
        </Box>
        {project.projectUrl && (
          <Link
            href={project.projectUrl}
            underline='none'
            target='_blank'
            rel='noopener'
          >
            <Button size='small' variant='outlined' startIcon={<LinkIcon />}>
              Project Link
            </Button>
          </Link>
        )}
        <Typography variant='body2' color={theme.palette.grey[600]} mt={1}>
          {project.description}
        </Typography>
      </Box>
    );
  });
  return (
    <Paper sx={{ padding: theme.spacing(2), height: '100%' }} elevation={2}>
      <Box
        display='flex'
        alignItems='center'
        marginTop={theme.spacing(1)}
        gap={theme.spacing(1)}
      >
        <LaptopChromebookIcon />
        <Typography variant='h5'>Projects</Typography>
      </Box>
      <Box mt={4}>{content}</Box>
    </Paper>
  );
};

export default ProjectDetails;
