import React, { useState } from 'react';
import Table from '@mui/material/Table';
import { Button, TableBody } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';

import { TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import Profile from './Profile';
import { applySortFilter, getComparator } from '../../halper/TableSort';
import ScoreBody from './ScoreBody';

const ScoreHeader = ({ score, setScore }) => {
  const [showProfile, setShowProfile] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = score.candidates || [];
  const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'profile', label: 'Profile', align: 'left' },
    { id: 'aptitude', label: 'Aptitude', align: 'left' },
    { id: 'domain', label: 'Domain', align: 'left' },
    { id: 'belong-score', label: 'Belong Score', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      {showProfile ? (
        <Profile scoreDetails={showProfile} setShowProfile={setShowProfile} />
      ) : (
        <div>
          <Button variant='outlined' mb={2} onClick={() => setScore(null)}>
            Back
          </Button>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
              aria-label='table'
            >
              <TableListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <ScoreBody
                      setShowProfile={setShowProfile}
                      employersAssessmentId={score._id}
                      row={row}
                      key={index}
                    />
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 50]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default ScoreHeader;
