import React, { useRef, useState } from 'react';
import { TextInput, SelectInput } from '../../_dashboard/layouts/FormElement';

import { useQuery } from 'react-query';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Typography, Stack } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../links/API_LINKS';
import axios from 'axios';

import { fetchCountry, fetchIndustry } from '../../../services/index';

const EmployerProfile = () => {
  const [serverResponse, setServerResponse] = useState();
  const navigate = useNavigate();
  const formikRef = useRef();

  const countries = useQuery('fetchCountry', fetchCountry);
  const industries = useQuery('fetchIndustry', fetchIndustry);

  return (
    <div>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          name: '',
          website: '',
          companyEmail: '',
          industryId: '',
          country: '',
          city: '',
          employeeName: '',
          employeeDesignation: '',
          password: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Please enter your company name'),
          companyEmail: Yup.string()
            .email('Email must be a valid email address')
            .required('Email is required'),
          industryId: Yup.string().required('Please select industry'),
          country: Yup.string().required('Please select country'),
          city: Yup.string().required('Please enter your company city'),
          employeeName: Yup.string().required('Please enter your name'),
          employeeDesignation: Yup.string().required(
            'Please enter your Designation'
          ),
          password: Yup.string().required('Password is required'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // setSubmitting(false);
          // navigate('/dashboard', { replace: true });

          setServerResponse('');
          axios({
            method: 'POST',
            url: API_URL + '/dashboard/employer/add-employee',
            data: values,
          })
            .then(function (response) {
              navigate('/login', { replace: true });
              setServerResponse(response.data.msg);
              setSubmitting(false);
            })
            .catch(function (response) {
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='name'
                  type='text'
                  label='Company Name'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='website'
                  type='text'
                  label='Company Website'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='city'
                  type='text'
                  label='city'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='country'
                  label='country'
                >
                  {countries.status === 'success' &&
                    Object.entries(countries.data.data.data).map(
                      ([countryCode, countryData]) => {
                        return (
                          <MenuItem key={countryCode} value={countryData.name}>
                            {countryData.name}
                          </MenuItem>
                        );
                      }
                    )}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='industryId'
                  label='Industry'
                >
                  {industries.status === 'success' &&
                    industries.data.data.data.map((indistry) => {
                      return (
                        <MenuItem key={indistry._id} value={indistry._id}>
                          {indistry.title}
                        </MenuItem>
                      );
                    })}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='employeeName'
                  type='text'
                  label='Employee Name'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='employeeDesignation'
                  type='text'
                  label='Employee Designation'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='companyEmail'
                  type='text'
                  label='Email'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='password'
                  type='password'
                  label='Password'
                />
              </Grid>
            </Grid>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Register
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmployerProfile;
