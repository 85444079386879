import React from 'react';
import { Box, Grid, Typography, List, ListItem } from '@mui/material';
import SectionChart from './SectionChart';

const ScoreChart = ({ data }) => {
  return (
    <Box
      mt={6}
      sx={{ p: 6, border: '1px solid', borderColor: 'primary.main' }}
      borderRadius
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SectionChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>
            The Aptitude test assesses on the following skills :
            <Box
              variant='span'
              sx={{ color: 'primary.main', display: 'inline', ml: 1 }}
            >
              Communication, Analytics, Creativity & Awareness
            </Box>
          </Typography>
          <List
            sx={{
              listStyleType: 'disc',
              pl: 2,
              '& .MuiListItem-root': {
                display: 'list-item',
              },
              fontSize: 14,
            }}
          >
            {data.aptitude?.bestAttempt?.finalScore.map((score) => {
              const percentage = Math.ceil((score.obtained * 100) / score.max);
              return (
                <ListItem>
                  {score.category.replace('_', ' ')}
                  <Typography
                    variant='span'
                    sx={{ ml: 1, color: 'primary.main' }}
                  >
                    {percentage}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScoreChart;
