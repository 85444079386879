import api from './api';

const fetchIndustry = async ({ params, headers }) => {
  const response = await api.get({ url: 'v2/industries', params, headers });
  return response;
};

const fetchIndustryWithParams = (functionArgs = {}) => {
  const { params, headers } = functionArgs;
  return async () => {
    const requestObject = { url: 'v2/industries' };
    if (params) requestObject.params = params;
    if (headers) requestObject.headers = params;
    const response = await api.get(requestObject);
    return response;
  };
};

export { fetchIndustry, fetchIndustryWithParams };
